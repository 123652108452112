import { Button, Form, Input, message, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { navigate } from "gatsby";
import { toLower } from "lodash";
import { get } from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import { LockIcon } from "../../../images";
import { useTranslation } from "../../../locales/useTranslation";
import getProfiles from "../../../newApi/profile/getProfiles";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import validateUsernameAndEmail from "../../../newApi/profile/validateUsernameAndEmail";

// markup
const LoginPage = (props) => {
  // const location = useLocation();
  const { t } = useTranslation();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  // const [showError, setShowError] = useState();

  function handleSubmit() {
    form
      .validateFields()
      .then((values) => {
        validateUsernameAndEmail({
          username: toLower(values?.username),
          email: values?.email,
        })
          .then((res) => {
            // console.log(res);
            let data = res?.data;

            let email = get(data, "email");
            if (email === values.email) {
              navigate(
                routes.emailVerification.to({
                  email: values.email,
                  username: toLower(values.username),
                })
              );
            } else {
              message.error(t("emailNotMatch"));
            }
          })
          .catch((err) => {
            message.error(err?.message);
          });
      })
      .catch((err) => {});
  }

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            showBack
            suffix={
              <div className="justify-end flex items-center">
                {/* 忘记密码 <MediaQuery maxWidth={440}><br/></MediaQuery>Forgot Password */}
                {t("forgotPassword2")}
              </div>
            }
          >
            <div className="p-4 " style={{ height: "105vh" }}>
              <Form form={form} style={{ color: "black" }}>
                <div className="flex justify-center items-center">
                  <img src={LockIcon} style={{ width: 200, height: 200 }} />
                </div>
                <div className="my-5 text-center font-semibold text-xl">
                  {/* 忘记密码
                  <br />
                  Forgot Password? */}
                  {t("forgotPassword")}
                </div>
                <div className="my-5 text-center text-sm font-thin px-5">
                  {/* 输入您的电子邮件以进行接收你的账户
                  <br /> */}
                  {t("forgotPasswordEmail")}
                </div>
                <div className="my-5 px-5">
                  <Form.Item
                    name="email"
                    style={{ margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: (
                          <React.Fragment>
                            {/* 请填写用户邮籍
                            <br /> */}
                            {t("emailRequired")}
                          </React.Fragment>
                        ),
                      },
                      {
                        type: "email",
                        message: (
                          <React.Fragment>
                            {/* 用户邮籍形式不正确
                            <br /> */}
                            {t("invalidEmail")}
                          </React.Fragment>
                        ),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("enterEmail")}
                      className={"input-border"}
                    />
                  </Form.Item>
                </div>
                <div className="my-5 px-5">
                  <Form.Item
                    name="username"
                    style={{ margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: (
                          <React.Fragment>
                            {/* 请填写用户邮籍
                            <br /> */}
                            {t("usernameRequired")}
                          </React.Fragment>
                        ),
                      },
                      {
                        validator: (_, value) => {
                          form.setFieldsValue({
                            username: toLower(value?.trim()),
                          });
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("usernameRequired")}
                      className={"input-border"}
                      style={{ textTransform: "lowercase" }}
                    />
                  </Form.Item>
                </div>
                {/* <div className="my-5 underline text-center text-blue-400 px-5">
                                    尝试其他方式 Try another ways
                                </div> */}
                <div className="my-5 flex justify-center items-center px-5">
                  <Button
                    className="main-button-color input-border"
                    block
                    onClick={(e) => {
                      handleSubmit();
                    }}
                  >
                    {/* 修改密码  */}
                    {t("resetPassword")}
                  </Button>
                </div>
              </Form>
            </div>
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
